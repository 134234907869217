import React from 'react';
import logo from './logo.svg';
import './App.css';
import Menu from './components/content/menu';
import Main from './components/content/main';
import Rosto from './components/content/rosto';
import Servicos from './components/content/servicos';
import Essere from './components/content/essere';
import Footer from './components/content/footer';
import Local from './components/content/local';
import Avaliacoes from './components/content/avaliacoes';
import Valores from './components/content/valores';
import Croche from './components/content/croche';
import { useEffect } from 'react';
import { useState } from 'react';



function App() {

  const[ativaCor, setAtivaCor] = useState(false);

  useEffect(function(){
    function posicaoScroll(){
      if(window.scrollY > 20){
        setAtivaCor(true);
      }else{
        setAtivaCor(false);
      }
    }

    window.addEventListener('scroll', posicaoScroll);
  }, []);

  return (
    <div className="App">
      <Rosto/>
        <div className="content">        
        <Servicos/> 
        <Essere/>
        <Croche/>        
      </div>   
      <Footer/>
    </div>
  );
}

export default App;
