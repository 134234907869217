import React from 'react';
import './style.scss'
import Logo from "../../assets/images/logo_transp.png";

class Rosto extends React.Component{
    render(){
        return(
            <div className="menu">
                <div className="logo">
                    <img src={Logo} alt="Essere Bambini"></img>                    
                </div>
                 
                <div className="btn">
                    <input type="button"   onClick={(e) => {
                            e.preventDefault();
                            window.open("https://wa.me/5511997566552?text=Oi!%20Gostaria%20de%20receber%20atendimento.", "_blank")
                        }} className="btnProd" value="Adquira já nossos produtos"/>
                </div>
       
            </div>              
        )
    }
}

export default Rosto;