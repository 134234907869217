import React from 'react';
import './servicos.css';
import ImgProdCroche from '../../assets/images/products/001.png';
import ImgKinesio from '../../assets/imagens/servicos/kinesio.jpg';
import ImgVentosa from '../../assets/imagens/servicos/ventosaterapia.jpg';
import ImgProdRoupa from '../../assets/images/products/006.png';
import CardColor from '../CardColor/cardColor';
import ImgSeparator from '../../assets/images/separator.png';

function Servicos() {
    return(
        <section id="servicos">
                         
            <div className="tituloInicio">
                    <h2 className='titgrey'>Bem-vindos à essere bambini!</h2>
                    <img className='separator' src={ImgSeparator}></img>
                    <h1 className='titgreen'>Conheça nossa linha de produtos</h1>               
                </div>

            <div className="row">
                <a className='linkProd' href='#croche'>
                <CardColor color="card red" 
                    image={ImgProdCroche}                     
                    title="Crochê"
                    text="Peças exclusivas em crochê, feitas à mão com todo o carinho e atenção aos detalhes. Cada peça é única, proporcionando conforto e estilo." 
                />    
                </a>                

                <a className='linkProd' href='#roupas'>
                <CardColor color="card blue" 
                    image={ImgProdRoupa}                     
                    title="Roupas infantis"
                    text="Roupas infantis selecionadas com cuidado, unindo conforto e qualidade excepcional para vestir os pequenos com estilo e suavidade." 
                />     
                </a>        
            </div>

            
        </section>
    );
}

export default Servicos;