import React from 'react';
import {useState, useEffect } from 'react';
import './croche.scss'
import ImgCroche01 from '../../assets/images/products/008.png';
import ImgCroche02 from '../../assets/images/products/002.png';
import ImgCroche03 from '../../assets/images/products/003.png';
import ImgCroche04 from '../../assets/images/products/004.png';
import ImgCroche05 from '../../assets/images/products/009.png';
import ImgCroche06 from '../../assets/images/products/007.png';
import ImgCroche07 from '../../assets/images/products/012.png';
import ImgSeparator from '../../assets/images/separator.png';
import {Swiper, SwiperSlide} from 'swiper/react';

function Croche() {

    const [slidePerView, setSlidePerView] = useState(3);

    const data = [
        { id: '1', image: ImgCroche01, desc: 'Espaço amplo e bem iluminado'},
        { id: '2', image: ImgCroche02, desc: 'Espaço amplo e bem iluminado'},
        { id: '3', image: ImgCroche03, desc: 'Espaço amplo e bem iluminado'},
        { id: '4', image: ImgCroche04, desc: 'Espaço amplo e bem iluminado'},
        { id: '5', image: ImgCroche05, desc: 'Espaço amplo e bem iluminado'},
        { id: '6', image: ImgCroche06, desc: 'Espaço amplo e bem iluminado'},
        { id: '7', image: ImgCroche07, desc: 'Espaço amplo e bem iluminado'},
    ];

    useEffect(() => {
        function handleResize(){
            if(window.innerWidth < 720) {
                setSlidePerView(1);
            }
            else{
                setSlidePerView(3);
            }
        }

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    return(
        <section id="croche" className="croche">
           
            <div className="tituloInicio">
                    <h2 className='titgrey'>Crochê</h2>
                    <img className='separator' src={ImgSeparator}></img>
                    <h1 className='titgreen'>Peças personalizadas e estilizadas</h1>                
            </div>

            <br/><br/>

            <Swiper 
                slidesPerView={slidePerView} 
                pagination={{clickable: true}}
                navigation
            >
                { data.map( (item) => (
                    <SwiperSlide key={item.id}>
                        <img src={item.image} alt={item.desc} className="slide-item" ></img>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
}

export default Croche;