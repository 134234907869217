import React from 'react';
import './essere.scss'
import ImgAmandaInterlick from '../../assets/imagens/profissionais/amanda.jpg';
import ImgLogo from '../../assets/imagens/logo.svg';
import ImgBaby from '../../assets/images/1.png';

function Essere() {

    return(
        <section id="esssere"className='content-essere'>
            <div className='leftImgMobile'></div>
            <div className="essere">
                
                <div className='leftImg'></div>

                <div className="rightBoard">
                    <div className='logoText'>
                        <p className='dizeres'>"Somos uma loja dedicada ao mundo encantador do crochê e das roupinhas de bebê, oferecendo peças pensadas com carinho e atenção a cada detalhe. Nosso compromisso é trazer conforto, estilo e delicadeza para que nosso cliente se sinta exclusivo e especial, com produtos de alta qualidade que aquecem e encantam."</p>
                        <h1 className='titulo'>CONHEÇA MAIS</h1>                    
                    </div>
                    
                    <input type="button"   onClick={(e) => {
                                e.preventDefault();
                                window.open("https://wa.me/5511997566552?text=Oi!%20Gostaria%20de%20receber%20atendimento.", "_blank")
                    }} className="btnAgendarInst" value="Entre em contato agora"/>

                </div> 
            </div>            
        </section>
    );
}

export default Essere;